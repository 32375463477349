import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KunnskapOgVeiledning from '../kunnskap-og-veiledning/KunnskapOgVeiledning';
import GlobaleKunnskapstekster from '../globale-kunnskapstekster/GlobaleKunnskapstekster';
import Kunnskapsmodellen from '../kunnskapsmodellen/Kunnskapsmodellen';
import Tooltip from '../tooltip/Tooltip';
import './KunnskapselementerPanel.scss';
import { DevOnly, IsProdEnvironment } from '../predicate-display/PredicateDisplay';

/**
 * To be able to switch between cards we have the 'activeCard' variable
 * that will keep track of which card to show in the front.
 *
 * These ID's are set to be able to use them as index values to fetch
 * relevant content.
 *
 *    0 = Kunnskap & Veiledning
 *    1 = Kunnskapsmodellen
 *    2 = Globale Kunnskapstekster
 */

/**
 * Array containing the card IDs.
 */
const CARD_IDS = [0, 1, 2];

/**
 * Array containing titles of the three cards
 */
const CARD_TITLES = [
  'Kunnskap & Veiledning',
  'Overordnet kvalitetsstøtte',
  'Kunnskapsmodellen',
];
/**
 * Array containing colors of the three cards
 */
const CARD_COLORS = ['#65A4BF', '#A6C4B0', '#bfbaba'];

/**
 * Array containing opaque colors of the three cards
 */
const CARD_COLORS_OPACITY = [
  'rgb(239 245 248)',
  'rgb(246 249 247)',
  'rgb(255,255,255)',
];

/**
 * Array containing titles of the three cards
 */
const CARD_DESCRIPTION = [
  'Viser alt innhold som er direkte relatert til hhv fase og/eller prosess.',
  'Viser alt innhold som er direkte relatert til den oppgaven du velger å klikke på. Om du ikke har klikket på noen oppgave vises alt innhold som er direkte relatert til hhv fase og/eller prosess',
  '',
];

/**
 * Array containing icons for the three cards
 */
const CARD_ICONS = [
  ['fal', 'lightbulb'],
  ['fal', 'globe'],
  ['far', 'triangle'],
];

/**
 * Array containing the component for the three cards
 */
const CARD_COMPONENT = [
  <KunnskapOgVeiledning
    title={CARD_TITLES[0]}
    icon={<FontAwesomeIcon color="white" icon={CARD_ICONS[0]} size="2x" />}
  />,
  <GlobaleKunnskapstekster
    title={CARD_TITLES[1]}
    icon={<FontAwesomeIcon color="white" icon={CARD_ICONS[1]} size="2x" />}
  />,
  <Kunnskapsmodellen
    title={CARD_TITLES[2]}
    icon={<FontAwesomeIcon color="white" icon={CARD_ICONS[2]} size="2x" />}
  />,
];

/**
 * @param {int} cardId
 * @param {int} index
 * @param {int} activeCard
 * @param {function} handleSetActiveCard
 * @param {function} handleEventSetActiveCard
 * @returns CardItem component
 */
const CardItem = ({
  cardId,
  index,
  activeCard,
  handleSetActiveCard,
  handleEventSetActiveCard,
}) => (
  <fieldset
    key={index}
    className={`card-stack__card ${
      activeCard === cardId
        ? 'card-stack__card--active'
        : 'card-stack__card--inactive'
    }`}
    style={{
      zIndex: index * -1 + 10,
      background: CARD_COLORS_OPACITY[cardId],
    }}
  >
    <legend
      data-for="main"
      data-tip={CARD_DESCRIPTION[cardId]}
      data-iscapture="true"
      id="tabElement"
      onClick={() => handleSetActiveCard(cardId)}
      onKeyPress={(e) => handleEventSetActiveCard(e, cardId)}
      role="tablist"
      tabIndex={0}
    >
      <Tooltip 
        title={CARD_DESCRIPTION[cardId]} 
        direction="left" delay="300"
        disableFocusListener={IsProdEnvironment()}
        disableHoverListener={IsProdEnvironment()}
        disableTouchListener={IsProdEnvironment()}
        >
        <FontAwesomeIcon color="#737373" icon={CARD_ICONS[cardId]} size="2x" />
      </Tooltip>
      <span style={{ background: CARD_COLORS[cardId], borderRadius: '2px' }}>
        {CARD_TITLES[cardId]}
      </span>
    </legend>
    {CARD_COMPONENT[cardId]}
  </fieldset>
);

/**
 * This component renders out 3 (for now) stacked cards that you can
 * switch between to easily access information relevant to what you
 * have selected in BFKvv.
 * @returns Stacked cards/panels containing relevant information for the BFKvv.
 */
const KunnskapselementerPanel = () => {
  const [activeCard, setActiveCard] = useState(0); // Initial state here is '0' which will be the front card in the beginning

  /**
   * A sorting function that always puts the active card id first and then sorts normally.
   * @param {int} a Compare element one
   * @param {int} b Compare element two
   * @returns Negative number, 0 or positive number.
   */
  const sortBySelectedCardId = (a, b) => {
    if (a === activeCard) {
      return -1;
    }
    if (b === activeCard) {
      return 1;
    }
    return a - b;
  };

  /**
   * Sets the active card based on the users selection.
   * @param {int} cardId Card id selected by the user
   */
  const handleSetActiveCard = (cardId) => {
    const tab = document.querySelectorAll('#tabElement');
    tab[0].tabIndex = '-1';
    tab[0].focus();
    setActiveCard(cardId);
  };

  /**
   * A key press event handler that sets the active card if user has
   * pressed 'Enter' when focusing on a card legend.
   * @param {any} e Event from user key press when focusing on a card element.
   * @param {int} cardId ID of card user has focused on.
   */
  const handleEventSetActiveCard = (e, cardId) => {
    if (e.key === 'Enter') {
      setActiveCard(cardId);
    }
  };

  return (
    <div className="card-stack">
      {CARD_IDS.sort(sortBySelectedCardId).map((id, i) => (
        <CardItem
          index={i}
          cardId={id}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          handleSetActiveCard={handleSetActiveCard}
          handleEventSetActiveCard={handleEventSetActiveCard}
        />
      ))}
    </div>
  );
};

export default KunnskapselementerPanel;
